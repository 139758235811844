<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Production Batches" class="bg-purple text-white">
                <div class="col-md-12">
                    <stats-card card-title="Production" card-sub-title="Batches of Production">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-8">
                    <show-card v-if="showRecordPage" v-bind:cardTitle="showRecordData.batch_name" v-bind:cardSubTitle="showRecordData.batch_id">                       
                        <div class="text-right">
                            <button type="button" @click="closeShowRecord" class="btn btn-light btn-sm mb-2"><i class="fas fa-window-close"></i> close</button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th><i class="fas fa-store"></i> &nbsp; &nbsp; Pen House</th>
                                        <td>{{showRecordData.pen_house}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-fish"></i> &nbsp; &nbsp; Livestock Type</th>
                                        <td>{{showRecordData.livestock_type}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-clock"></i> &nbsp; &nbsp; Created On</th>
                                        <td>{{showRecordData.created_at}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hourglass-start"></i> &nbsp; &nbsp; Duration Week</th>
                                        <td>{{showRecordData.duration_week}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-calculator"></i> &nbsp; &nbsp; Opening Stock Qty</th>
                                        <td>{{showRecordData.opening_stock_qty}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-weight"></i> &nbsp; &nbsp; Opening Stock Weight (kg)</th>
                                        <td>{{showRecordData.opening_stock_weight}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-weight"></i> &nbsp; &nbsp; Closing Stock Weight (kg)</th>
                                        <td>{{showRecordData.closing_stock_weight ?? '-'}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hourglass-end"></i> &nbsp; &nbsp; Completed On</th>
                                        <td>{{showRecordData.completed_on ?? '-'}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-user-cog"></i> &nbsp; &nbsp; Manager</th>
                                        <td>{{showRecordData.batch_manager.username}} </td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-store"></i> &nbsp; &nbsp; Branch</th>
                                        <td>{{showRecordData.branch.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn mt-5 mb-3 text-center" role="group" aria-label="production-actions">
                            <button @click="addResources(showRecordData.batch_id)" class="btn btn-dark">Resource Usages</button>
                            <button @click="addMortalities(showRecordData.batch_id)" class="btn btn-danger">Mortalities</button>
                            <button @click="assignEmployee(showRecordData.batch_id)" class="btn btn-primary">Assign Employee</button>
                        </div>
                         <resource-usage-form v-if="addResourcesForm && showRecordData.status == 'started'" :batchId="showRecordData.batch_id" @success="reloadComponent"/>
                         <mortality-form v-if="addMortalitiesForm && showRecordData.status == 'started'" :batchId="showRecordData.batch_id" @success="reloadComponent"/>
                         <assignment-form v-if="addAssignmentForm && showRecordData.status == 'started'" :batchId="showRecordData.batch_id" @success="reloadComponent"/>  
                        <resource-usage-report v-if="showReports && addResourcesForm" :batchId="showRecordData.batch_id" :batchName="showRecordData.batch_name"/>
                        <mortality-report v-if="showReports && addMortalitiesForm" :batchId="showRecordData.batch_id" :batchName="showRecordData.batch_name"/>
                        <assignment-report v-if="showReports && addAssignmentForm" :batchId="showRecordData.batch_id" :batchName="showRecordData.batch_name"/>
                        <div class="text-right">
                            <button type="button" @click="closeShowRecord" class="btn btn-light btn-sm"><i class="fas fa-window-close"></i> close</button>
                        </div>
                    </show-card>
                    <table-card card-title="All Productions Batches" v-else>
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Pen House</th>
                                <th scope="col">Livestock</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.batch_id">
                                <td>{{ record.batch_name }}</td>
                                <td>{{ record.pen_house }}</td>
                                <td>{{ record.livestock_type }}</td>
                                <td>
                                    <button @click="openShowRecord(record)" class="btn btn-dark btn-sm"><i class="fas fa-eye"></i></button>
                                    <button v-if="record.status == 'started'" @click="openUpdateForm(record)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-4">
                    <form-card card-title="Edit Record Form" card-sub-title="Update Production" v-if="showUpdateForm">
                        <form v-on:submit.prevent="updateForm">
                            <div class="form-group">
                                <div>
                                    <label>Pen House Number</label>
                                    <input class="form-control" type="text" v-model="form.pen_house">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Livestock Type</label>
                                    <select class="form-control" v-model="form.livestock_type">
                                        <option v-for="option in livestocks" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Closing Stock Weight (kg)</label>
                                    <input class="form-control" type="number" step="0.01" v-model="form.closing_stock_weight" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Status</label>
                                    <select class="form-control" v-model="form.status">
                                        <option value="" disabled selected>Select Status</option>
                                        <option v-for="option in statuses" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <div>
                                    <label>Completed On</label>
                                    <input class="form-control" type="date" v-model="form.completed_on">
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div>
                                    <label>Select Manager</label>
                                    <select class="form-control" v-model="form.manager_id">
                                        <option v-for="option in users" :value="option.user_id" :key="option.user_id">{{option.username}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-warning my-4"><i class="ni ni-check-bold"></i> Update</button> &nbsp; 
                                <button @click="closeUpdateForm" class="btn btn-default my-4"><i class="ni ni-fat-remove"></i> Close</button>
                            </div>
                        </form>
                    </form-card>
                    <form-card card-title="New Form" card-sub-title="Create Production Batch" v-else>
                        <form v-on:submit.prevent="createForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Batch Name</label>
                                    <input class="form-control" type="text" v-model="form.batch_name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Pen House Number</label>
                                    <input class="form-control" type="text" v-model="form.pen_house">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Livestock Type</label>
                                    <select class="form-control" v-model="form.livestock_type">
                                        <option value="" disabled selected>Select Livestock Type</option>
                                        <option v-for="option in livestocks" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Opening Stock Qty</label>
                                    <input class="form-control" type="number" v-model="form.opening_stock_qty" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Opening Stock Weight (kg)</label>
                                    <input class="form-control" type="number" step="0.01" v-model="form.opening_stock_weight" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Duration (in Weeks)</label>
                                    <input class="form-control" type="number" v-model="form.duration_week" onwheel="this.blur()">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Select Manager</label>
                                    <select class="form-control" v-model="form.manager_id">
                                        <option v-for="option in users" :value="option.user_id" :key="option.user_id">{{option.username}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Create</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
import TwoButtonColorCardVue from '../../components/Cards/TwoButtonColorCard.vue';
import ResourceUsageVue from './Production/ResourceUsageForm.vue';
import ResourceUsageReportsVue from './Production/ResourceUsageReports.vue';
import MortalityReportsVue from './Production/MortalityReports.vue';
import MortalityFormVue from './Production/MortalityForm.vue';
import AssignmentFormVue from './Production/AssignmentForm.vue';
import AssignmentReportsVue from './Production/AssignmentReports.vue';
export default {
    name: 'AdminProductionBatches',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue, 
        'show-card': TwoButtonColorCardVue,
        'resource-usage-form':ResourceUsageVue,
        'mortality-form': MortalityFormVue,
        'assignment-form': AssignmentFormVue,
        'resource-usage-report': ResourceUsageReportsVue,
        'mortality-report': MortalityReportsVue,
        'assignment-report': AssignmentReportsVue
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken,
            branch : state => state.branch,
        })
    },
    mounted(){
        if (this.authToken) {
            this.getRecords()
            this.fetchUsers()
        }
        else{
            this.$router.push('/login')
        }
    },
    data() {
        return{
            records: null,
            users: null,
            statuses : ['completed'],
            livestocks : ['poultry', 'fishes'],
            showUpdateForm: false,
            showRecordPage: false,
            addResourcesForm: false,
            addMortalitiesForm: false,
            addAssignmentForm: false,
            showReports:true,
            form:{
                batch_name: null,
                pen_house: null,
                livestock_type: '',
                opening_stock_qty: null,
                opening_stock_weight: null,
                duration_week: null,
                manager_id: '',
                closing_stock_weight: null,
                status: '',
                completed_on: null
            }
        }
    },
    methods:{
        createForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/production/batches?branch_id='+this.branch.branch_id, this.form,{ headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then((response) => {
                    //Perform Success Action
                    this.$moshaToast(response.data.message,{
                        type: 'success'
                    });
                    this.getRecords();
                 })
                 .catch((error) => {
                    // error.response.status Check status code
                     this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                 })
                 .finally(() => {
                    //Perform other dependent actions
                    loader.hide()
                 });
        },

        updateForm(){
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/admin/production/batches/'+this.recordId+'?branch_id='+this.branch.branch_id, this.form,{ 
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then(() => {
                    //Perform Success Action
                    this.$moshaToast('Update Successful!',{
                        type: 'success'
                    });
                    this.getRecords();
                 })
                 .catch((error) => {
                    // error.response.status Check status code
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                 })
                 .finally(() => {
                    //Perform other dependent actions
                    loader.hide()
                 });
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/batches',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
                this.showRecordPage = false
                this.form = {}
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
                if (error.response.status === 401) {
                    localStorage.removeItem('mytoken');
                    this.$router.push('/login');
                }
            })
        },

        fetchUsers(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/users?category=manager',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.users = response.data.data
            })
        },
        
        reloadComponent(){
            this.showReports = false;
            this.$nextTick(() => {
                this.showReports = true;
            });
        },

        openUpdateForm(record){
            this.showUpdateForm = true,
            this.recordId = record.batch_id,
            this.form.pen_house = record.pen_house,
            this.form.livestock_type = record.livestock_type,
            this.form.status = record.status,
            this.form.closing_stock_weight = record.closing_stock_weight,
            this.form.completed_on = record.completed_on,
            this.form.manager_id = record.batch_manager.user_id
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form.batch_name = null,
            this.form.pen_house = null,
            this.form.livestock_type = '',
            this.form.opening_stock_qty = null,
            this.form.opening_stock_weight = null,
            this.form.duration_week = null,
            this.form.manager_id = ''
        },
        openShowRecord(record){
            this.showRecordPage = true,
            this.showRecordData = record
        },
        closeShowRecord(){
            this.showRecordPage = false,
            this.addResourcesForm = false,
            this.addMortalitiesForm = false,
            this.addAssignmentForm = false
        },
        addResources(){
            this.addResourcesForm = !this.addResourcesForm,
            this.addMortalitiesForm = false,
            this.addAssignmentForm = false
        },
        addMortalities(){
            this.addMortalitiesForm = !this.addMortalitiesForm,
            this.addResourcesForm = false,
            this.addAssignmentForm = false
        },
        assignEmployee(){
            this.addAssignmentForm = !this.addAssignmentForm,
            this.addMortalitiesForm = false,
            this.addResourcesForm = false
        }

    },
}
</script>