<template>
    <div>
        <stats-card card-sub-title="" card-title="Resource Usage Reports" class="bg-white text-dark">
            <h5 class="text-white mt-2 bg-dark p-2">BATCH NAME: {{batchName}}</h5>
            <h3 class="badge badge-primary">Total Amount Spent: <span>₦ {{totalCost.toLocaleString()}}</span></h3>
            <ul v-if="records">
               <li v-for="record in records" :key="record.utility_id">
                   <span class="text-blue">{{record.qty}} {{record.unit}}</span> of {{record.type}} was used on <span class="badge badge-primary">{{record.created_at}}</span> at a cost of ₦{{record.total_cost.toLocaleString()}} described as: {{record.utility_name}}

                   <span class="text-right">
                       <button type="button" @click="deleteRecord(record.utility_id)" class="btn btn-sm"><i class="fas fa-times text-red"></i> </button>
                   </span>
               </li>
            </ul>
            <div v-else> Nothing to see</div>
        </stats-card>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import StatsCardVue from '../../../components/Cards/StatsCard.vue';
export default {
    name: 'ProductionResourceUsageReports',
    props:[
        'batchId', 'batchName'
    ],
    computed: {
        totalCost() {
            if (this.records) {
                return this.records.reduce((acc, item) => acc + item.total_cost, 0);                
            }else{
                return null
            }
        },
        ...mapState({
            authToken : state => state.authToken
        })
    },
    data(){
        return{
            records: []
        }
    },
    components:{
        'stats-card':StatsCardVue
    },
    mounted(){
        this.fetchRecords()        
    },
    methods:{
        fetchRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/utilities?batch_id='+this.batchId,{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },

        deleteRecord(record){
            if(confirm("Do you really want to delete?")){
                axios.delete(process.env.VUE_APP_BASEURL+'/admin/production/utilities/'+record+'?confirm=yes',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                .then(() => {
                    this.$moshaToast('Deleted Successfully!!',{
                        type: 'success'
                    });
                    this.fetchRecords()
                })
                .catch((error) => {
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                })  
            }
        },
    }
}
</script>
<style scoped>
ul{
    margin-left: 0;
    padding-inline-start: 5px;
}
li{
    border-bottom: 1px solid rgb(81, 235, 158);
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 13px;
}
</style>