<template>
    <form-card card-title="Record Resource Usage" card-sub-title="" class="bg-light">
        <form v-on:submit.prevent="resourceUsageForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div>
                            <label>Utility Description</label>
                            <input class="form-control" type="text" v-model="form.utility_name">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div>
                            <label>Resource Type</label>
                            <select class="form-control" v-model="form.type">
                                <option value="" disabled selected>Select Resource Type</option>
                                <option v-for="option in type" :value="option" :key="option">{{option}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div>
                            <label>Quantity</label>
                            <input class="form-control" type="number" step="0.1" v-model="form.qty" min="1" wheel="this.blur()">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div>
                            <label>Unit of Measurement</label>
                            <select class="form-control" v-model="form.unit">
                                <option value="" disabled selected>Select Unit</option>
                                <option v-for="option in unit" :value="option" :key="option">{{option}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div>
                            
                            <label>Amount Spent</label>
                            <input class="form-control" type="number" step="0.01" v-model="form.total_cost" min="1" onwheel="this.blur()">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center">
                <button type="submit" class="btn btn-success my-4"><i class="ni ni-check-bold"></i> Save Record</button>
            </div>
        </form>
    </form-card>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import NoButtonWhiteCardVue from '../../../components/Cards/NoButtonWhiteCard.vue'
export default {
  name: 'ProductionResourceUsage',
  components:{
      'form-card': NoButtonWhiteCardVue
  },
  props:[
      'batchId'
  ],
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
  data(){
      return{
          type : ['woodshaving', 'water', 'feed', 'vaccine', 'drug', 'charcoal', 'other'],
          unit : ['kg', 'metres', 'litres', 'cartons', 'pieces', 'bags', 'sets'],
          form:{
              utility_name: null,
              type: '',
              qty: null,
              unit: '',
              total_cost: null
          }
      }
  },
  methods:{
        resourceUsageForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/production/utilities?batch_id='+this.batchId, this.form,{ 
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.form = {}
                this.$emit('success',true)
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                loader.hide()
            });
        }
  }
}
</script>